import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from '@core-app/services/auth.service';
import {
  fetchAllCompaniesRelatedToArticles,
  fetchNotifications,
  showModal,
} from '@core-app/state/ui';
import { Store } from '@ngrx/store';
import { FeatureFlagsService } from './services/feature-flags.service';
import { ModalService } from './services/modal.service';

@Component({
  selector: 'frontend-workspace-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  private readonly _modalService = inject(ModalService);
  private readonly _store = inject(Store);

  readonly _featureFlagService = inject(FeatureFlagsService);

  constructor(private readonly _authService: AuthService) {
    this._authService.saveAuthenticatedUser();
    this._modalService.init();
  }

  ngOnInit() {
    this._store.dispatch(fetchNotifications());
    this._store.dispatch(fetchAllCompaniesRelatedToArticles());
  }

  openSupportDialog() {
    this._store.dispatch(
      showModal({
        modalKey: 'support',
        data: { title: 'asdsad' },
        width: '900px',
      }),
    );
  }
}
