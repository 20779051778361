<div class="part-search-modal">
  <div class="flex">
    <div class="flex-1 p-4">
      <form
        class="flex flex-col gap-2 md:!flex-row"
        [formGroup]="searchFormGroup"
        (submit)="submit()"
      >
        <fip-form class="flex-1">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label data-cy="part-input-label">Part Number</mat-label>
            <input
              data-cy="part-input-field"
              matInput
              formControlName="partNumber"
              type="text"
              placeholder="e.g. P1234.."
            />
          </mat-form-field>
        </fip-form>
        <fip-form formType="submit" class="h-10 md:h-auto">
          <fip-button
            dataCy="part-search-modal-btn"
            [loading]="(searchLoading$ | async)!"
            (clickEvent)="submit()"
            label="Search"
            backgroundColor="black"
            textColor="white"
            classes="px-3 py-2 md:ml-3 md:mt-0 sm:w-auto shadow-sm text-sm"
          ></fip-button>
        </fip-form>
      </form>
    </div>
    <div
      data-cy="serial-numbers-list"
      class="hidden flex-1 self-stretch bg-gray-100 px-4 pb-10 pt-4 md:block"
    >
      <h4 class="text-base font-semibold leading-6 text-gray-900">Recent</h4>
      <ng-container
        *ngIf="!(savedSerialNumbers$ | async)?.length; else savedSerialNumbersContainer"
      >
        <p class="text-sm text-gray-500">You have no recent search.</p>
      </ng-container>
      <ng-template #savedSerialNumbersContainer>
        <button
          class="group mt-2 block text-sm"
          *ngFor="let item of savedSerialNumbers$ | async"
          (click)="submit(item.articleNumber)"
        >
          <p
            class="text-sm font-semibold leading-6 text-gray-900 group-hover:text-gray-500"
          >
            - {{ item.articleNumber }}
          </p>
        </button>
      </ng-template>
    </div>
  </div>
</div>
