import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';

@Component({
  selector: 'fip-image-modal',
  templateUrl: './image.modal.html',
  styleUrls: ['./image.modal.scss'],
  standalone: false,
})
export class ImageModalComponent {
  readonly modalKey: ModalKey = 'image';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      imageUrl: string;
    },
  ) {}
}
