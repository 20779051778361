import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as SearchActions from '@core-app/state/search';
import { selectCompaniesByArticleId } from '@core-app/state/search';
import {
  searchLoading,
  selectPlannedBomArticleSearchResult,
  selectSavedPlannedBomArticles,
} from '@core-app/state/search/search.selectors';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { ArticleSearchResult, Company, ProductionNumber } from '@interfaces';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'fip-production-planned-bom-modal',
  templateUrl: './production-planned-bom.modal.html',
  styleUrls: ['./production-planned-bom.modal.scss'],
  standalone: false,
})
export class ProductionPlannedBomModalComponent implements OnDestroy {
  readonly modalKey: ModalKey = 'production_planned_bom';
  readonly displayedColumns = ['batch'];
  readonly columns = [
    {
      index: 'batch',
      label: 'Batch',
      key: 'batchId' as keyof ArticleSearchResult,
    },
  ];
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store);
  private readonly _router = inject(Router);

  searchFormGroup = new FormGroup({
    articleNumber: new FormControl('', [Validators.required]),
    companyCode: new FormControl('', [Validators.required]),
  });
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: ProductionNumber[];
  savedArticles$ = this._store.select(selectSavedPlannedBomArticles);
  companies$: Observable<Company[] | null> = of(null);

  constructor() {
    // Disable form group while loading...
    this.searchLoading$.pipe(takeUntilDestroyed()).subscribe((loading) => {
      if (loading) {
        this.searchFormGroup.disable();
      } else {
        this.searchFormGroup.enable();
      }
    });
  }

  getCompaniesByArticle(
    articleNumber?: string,
    isComingFromSavedList?: boolean,
  ) {
    const companiesLoaded$ = new Subject();

    if (!articleNumber || articleNumber.length < 8) {
      return;
    }

    this.companies$ = this._store.select(
      selectCompaniesByArticleId(articleNumber),
    );

    this.companies$
      .pipe(takeUntil(companiesLoaded$), takeUntilDestroyed(this._destroyRef))
      .subscribe((companies) => {
        if (companies?.length) {
          companiesLoaded$.next(true);

          if (isComingFromSavedList) {
            this.submit();
          } else {
            this.searchFormGroup
              .get('companyCode')
              ?.setValue(companies[0].code);
          }
        }
      });

    this._store.dispatch(SearchActions.getCompanies({ articleNumber }));
  }

  loadSavedSearchResult(article: string, company: string) {
    // If we are selecting an item from the saved company codes list
    if (company) {
      this.searchFormGroup.get('companyCode')?.setValue(company);
    }

    // If we are selecting an item from the saved article IDs list
    if (article) {
      this.searchFormGroup.get('articleNumber')?.setValue(article);
      this.getCompaniesByArticle(article, true);
    }
  }

  submit() {
    this.searchResult = undefined;

    const { articleNumber, companyCode } = this.searchFormGroup.value;

    if (!articleNumber || !companyCode) {
      return;
    }

    let companyName = '';

    this.companies$ = this._store.select(
      selectCompaniesByArticleId(articleNumber),
    );

    this.companies$.pipe(take(1)).subscribe((companies) => {
      if (companies) {
        const firstCompany = companies?.filter(
          (company) => company.code === companyCode,
        )[0];
        companyName = firstCompany.name || '';

        // Listen to search result from ngrx store
        this._store
          .select(
            selectPlannedBomArticleSearchResult(articleNumber, companyCode),
          )
          .pipe(
            takeUntilDestroyed(this._destroyRef),
            tap((data) => {
              if (!data) {
                if (articleNumber && companyCode) {
                  this._store.dispatch(
                    SearchActions.loadPlannedBomArticle({
                      articleNumber,
                      searchType: this.modalKey,
                      companyCode,
                      companyName,
                    }),
                  );
                }
              }
            }),
            tap((data) => {
              if (data) {
                if (articleNumber && companyCode) {
                  this._store.dispatch(
                    SearchActions.reorderSearchResults({
                      searchType: 'plannedBomArticleSearchResults',
                      payload: {
                        articleNumber,
                        companyCode,
                        companyName,
                      },
                    }),
                  );
                }
              }
            }),
          )
          .subscribe((data) => {
            this.searchResult = data;
            const { articleNumber, companyCode } = this.searchFormGroup.value;

            if (data?.length) {
              this._router.navigate([
                `/production/stock/${articleNumber}/${companyCode}`,
              ]);
            }
          });
      }
    });
  }

  ngOnDestroy() {
    // Clear search results before closing modal
    this.searchResult = undefined;

    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );
  }
}
