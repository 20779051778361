import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  getMeasurementsModalData,
  setMeasurementsModalData,
} from '@core-app/state/batch/batch.actions';
import { MeasurementsWrapper } from '@core-app/state/batch/batch.reducer';
import {
  selectMeasurementsModalData,
  selectMeasurementsModalLoading,
} from '@core-app/state/batch/batch.selectors';
import { SearchState } from '@core-app/state/search/search.reducer';
import { TableColumn } from '@frontend-workspace/shared/src/lib/components/table/table.component';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { InspectionType, ProcessType, ComponentDataState } from '@enums';
import { DATE_AND_TIME_FORMAT, formatToDate } from '@helpers';
import {
  InlineInspection,
  Measurement,
  ProcessParameter,
  SimpleMeasurement,
  LineChartDataPoints,
} from '@interfaces';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'fip-measurements-modal',
  templateUrl: './measurements.modal.html',
  styleUrls: ['./measurements.modal.scss'],
  standalone: false,
})
export class MeasurementsModalComponent implements OnInit {
  readonly modalKey: ModalKey = 'inspections_measurements';
  private readonly _store = inject(Store<SearchState>);
  private readonly _destroyRef = inject(DestroyRef);

  modalData$: Observable<MeasurementsWrapper | null> = this._store.select(
    selectMeasurementsModalData(),
  );
  dataState: ComponentDataState = ComponentDataState.Loading;
  ComponentDataState = ComponentDataState;
  loading$ = this._store.select(selectMeasurementsModalLoading());
  chartData: LineChartDataPoints[] = [];

  columns: TableColumn<SimpleMeasurement>[] = [
    {
      index: 'timestamp',
      label: 'Timestamp',
      key: 'timestamp',
    },
    {
      index: 'value',
      label: 'Value',
      key: 'value',
    },
  ];

  displayedColumns: (keyof SimpleMeasurement)[] = ['timestamp', 'value'];
  xTicksVals: number[] = [];
  xTicksLabels: string[] = [];
  yAxisLabel = 'Value';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productionOrderId: InlineInspection['productionOrderId'];
      nodeId: ProcessParameter['nodeId'];
      articleId: InlineInspection['articleId'];
      batchId: InlineInspection['batchId'];
      rootBatchId: InlineInspection['rootBatchId'];
      rootArticleId: InlineInspection['rootArticleId'];
      inspectionType: InspectionType;
      processType: ProcessType;
    },
  ) {}

  ngOnInit(): void {
    if (this.data.inspectionType === InspectionType.Inline) {
      this._store.dispatch(
        getMeasurementsModalData({
          productionOrderId: this.data.productionOrderId,
          nodeId: this.data.nodeId,
          articleId: this.data.articleId,
          batchId: this.data.batchId,
          rootBatchId: this.data.rootBatchId,
          rootArticleId: this.data.rootArticleId,
          processType: this.data.processType,
        }),
      );
    } else {
      // We need to store data in localStorage so that it's available between page reloads
      // We can't use the url to store this one as the data can be too large
      const data = localStorage.getItem('measurementsModalData');

      if (data) {
        this._store.dispatch(
          setMeasurementsModalData({
            data: JSON.parse(data)[0] as MeasurementsWrapper,
          }),
        );
      }
    }

    this.modalData$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => {
        if (!data) {
          return;
        }

        const measurements = this.sortMeasurements(
          data.measurements,
        ) as SimpleMeasurement[];

        if (data) {
          this.yAxisLabel = data.displayName;
        }
        this.setupData(measurements);
      });
  }

  setupData(measurements: Measurement[] | SimpleMeasurement[]) {
    this.setDataState(measurements);
    this.setChartData(measurements);

    this.setXTicksValsAndLabels(measurements);
  }

  getMeasurementTime(measurement: SimpleMeasurement | Measurement) {
    if ('timestamp' in measurement) {
      return new Date(measurement.timestamp || '').getTime();
    }
    if ('measuredValueTs' in measurement) {
      return new Date(measurement.measuredValueTs || '').getTime();
    }

    return 0;
  }

  setChartData(measurements: SimpleMeasurement[] | Measurement[]) {
    const xArray: number[] = [];
    const yArray: number[] = [];

    if (!measurements.length) {
      return;
    }

    if (this.data.inspectionType === InspectionType.Inline) {
      measurements = measurements as SimpleMeasurement[];
    } else {
      measurements = measurements as Measurement[];
    }

    const startTime = this.getMeasurementTime(measurements[0]);

    measurements.forEach((measurement) => {
      // Date values are based on relative distance from first date, in seconds
      const diff = parseFloat(
        ((this.getMeasurementTime(measurement) - startTime) / 1000).toFixed(2),
      );

      if ('value' in measurement) {
        if (measurement.value !== null) {
          xArray.push(diff);
          yArray.push(parseInt(measurement.value));
        }
      } else if ('measuredValue' in measurement) {
        xArray.push(diff);
        yArray.push(measurement.measuredValue);
      }
    });

    const chartData: LineChartDataPoints[] = [];
    chartData.push(<LineChartDataPoints>{
      x: xArray,
      y: yArray,
    });

    this.chartData = chartData;
  }

  // Sort measurements based on timestamp so that they are in correct order for the chart
  sortMeasurements(data: SimpleMeasurement[] | Measurement[]) {
    if (this.data.inspectionType === InspectionType.Inline) {
      data = data as SimpleMeasurement[];
    } else {
      data = data as Measurement[];
    }

    return [...data].sort((a, b) => {
      return this.getMeasurementTime(a) - this.getMeasurementTime(b);
    });
  }

  setDataState(data: Measurement[] | SimpleMeasurement[]) {
    if (data.length === 0) {
      this.dataState = ComponentDataState.NoData;
    } else {
      this.dataState = ComponentDataState.HasData;
    }
  }

  setXTicksValsAndLabels(measurements: SimpleMeasurement[] | Measurement[]) {
    const xTicksVals: number[] = [];
    const xTicksLabels: string[] = [];

    if (!measurements.length) {
      return;
    }

    if (this.data.inspectionType === InspectionType.Inline) {
      measurements = measurements as SimpleMeasurement[];
    } else {
      measurements = measurements as Measurement[];
    }

    const startTimestamp = this.getMeasurementTime(measurements[0]);

    // Time difference between first and last measurement
    const timeDiff = Math.floor(
      this.getMeasurementTime(measurements[measurements.length - 1]) -
        startTimestamp,
    );

    if (measurements.length >= 5) {
      // Add first and last measurement to ticks and 4 values in between
      for (let i = 0; i < 6; i++) {
        const value = startTimestamp + Math.floor((timeDiff / 5) * i);
        xTicksVals.push(((timeDiff / 5) * i) / 1000);
        xTicksLabels.push(
          formatToDate(new Date(value).toString(), DATE_AND_TIME_FORMAT) || '',
        );
      }
    } else {
      for (let i = 0; i < measurements.length; i++) {
        const value = this.getMeasurementTime(measurements[i]);
        xTicksVals.push(value);
        xTicksLabels.push(
          formatToDate(new Date(value).toString(), DATE_AND_TIME_FORMAT) || '',
        );
      }
    }

    this.xTicksVals = xTicksVals;
    this.xTicksLabels = xTicksLabels;
  }
}
