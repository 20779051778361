import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as SearchActions from '@core-app/state/search/search.actions';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import {
  searchLoading,
  selectSavedSerialNumbers,
  selectSerialSearchResultsBySerialNumber,
} from '@core-app/state/search/search.selectors';
import { SerialSearchResult } from '@interfaces';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'frontend-workspace-part-search-periodic',
  templateUrl: './part-search-periodic.component.html',
  styleUrls: ['./part-search-periodic.component.scss'],
  standalone: false,
})
export class PartSearchPeriodicComponent implements OnDestroy {
  readonly modalKey: ModalKey = 'part-search_periodic-inspections';
  private readonly _destroyRef = inject(DestroyRef);
  savedSerialNumbers$ = this._store.select(selectSavedSerialNumbers);
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: SerialSearchResult;
  searchFormGroup = new FormGroup({
    partNumber: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly _store: Store,
    private readonly _router: Router,
  ) {
    // Disable form group while loading...
    this.searchLoading$.pipe(takeUntilDestroyed()).subscribe((loading) => {
      if (loading) {
        this.searchFormGroup.disable();
      } else {
        this.searchFormGroup.enable();
      }
    });
  }

  submit(serialNumber?: string) {
    if (serialNumber) {
      this.searchFormGroup.get('partNumber')?.setValue(serialNumber);
    }

    const param = serialNumber ?? this.searchFormGroup.value.partNumber;
    if (!param || !this.searchFormGroup.valid) {
      return;
    }

    const partNumber = this.searchFormGroup.value.partNumber;

    if (partNumber) {
      // Listen to search result from ngrx store
      this._store
        .select(selectSerialSearchResultsBySerialNumber(partNumber))
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          tap((data) => {
            if (!data) {
              this._store.dispatch(
                SearchActions.loadPartBySerialNumber({
                  serialNumber: param,
                  save: true,
                }),
              );
            }
          }),
          filter((data) => !!data),
          tap(() => {
            this._store.dispatch(
              SearchActions.reorderSearchResults({
                searchType: 'serialNumbers',
                payload: partNumber,
              }),
            );
          }),
        )
        .subscribe((data) => (this.searchResult = data));
    }
  }

  navigateToPeriodicInspections() {
    this._router.navigate(['batch', this.searchResult?.batchId, 'periodic'], {
      queryParams: {
        rootArticleId: this.searchResult?.articleId,
        articleId: this.searchResult?.articleId,
        batchIds: this.searchResult?.batchId,
      },
    });
  }

  ngOnDestroy() {
    // Clear search retsult before closing modal
    this.searchResult = undefined;
    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );
  }
}
