import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import {
  ArticlePayload,
  ArticleSearchResult,
  Company,
  ProductionNumber,
  ScannedItem,
  SerialSearchResult,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { Dayjs } from 'dayjs';

export const loadPartBySerialNumber = createAction(
  '[Search/API] Load Part By Serial Number',
  props<{ serialNumber: string; save: boolean }>(),
);

export const loadPartBySerialNumberSuccess = createAction(
  '[Search/API] Load Part By Serial Number Success',
  props<{
    serialNumber: string;
    serialSearchResult: SerialSearchResult | null;
    save: boolean;
  }>(),
);

export const loadPartBySerialNumberFailure = createAction(
  '[Search/API] Load Part By Serial Number Failure',
  props<{ error: string | null | undefined }>(),
);

export const reorderSearchResults = createAction(
  '[Search/API] Reorder Search Results',
  props<{
    searchType:
      | 'serialNumbers'
      | 'savedArticlePayloads'
      | 'plannedBomArticleSearchResults'
      | 'spcArticleSearchResults'
      | 'scannedEquipmentArticleSearchResults';
    payload:
      | string
      | object
      | (Omit<ArticlePayload, 'productionFrom' | 'productionUntil'> & {
          productionFrom: string;
          productionUntil: string;
        });
  }>(),
);

export const loadArticle = createAction(
  '[Search/API] Load Article',
  props<{
    articleNumber: string;
    productionFrom: Dayjs;
    productionUntil: Dayjs;
    searchType: ModalKey;
  }>(),
);

export const loadArticleSuccess = createAction(
  '[Search/API] Load Article Success',
  props<{
    articlePayload: ArticlePayload;
    articleSearchResult: ArticleSearchResult[] | null;
  }>(),
);

export const loadPlannedBomArticle = createAction(
  '[Search/API] Load Planned BOM Article',
  props<{
    articleNumber: string;
    searchType: ModalKey;
    companyCode: string;
    companyName: string;
  }>(),
);

export const loadPlannedBomArticleSuccess = createAction(
  '[Search/API] Load Planned BOM Article Success',
  props<{
    articleNumber: string;
    companyCode: string;
    companyName: string;
    results: ProductionNumber[] | null;
  }>(),
);

export const loadSpcArticle = createAction(
  '[Search/API] Load SPC Article',
  props<{
    articleNumber: string;
    productionFrom: Dayjs;
    productionUntil: Dayjs;
    searchType: ModalKey;
  }>(),
);

export const loadSpcArticleSuccess = createAction(
  '[Search/API] Load SPC Article Success',
  props<{
    articlePayload: ArticlePayload;
    articleSearchResult: ArticleSearchResult[] | null;
  }>(),
);

export const toggleSearchLoading = createAction(
  '[Search/API] Toggle Search Loading',
  props<{ searchType: ModalKey; loading: boolean }>(),
);

export const clearSearchResult = createAction(
  '[Search/API] Clear Search Result',
);

export const getSavedSerialNumbers = createAction(
  '[Search/API] Get Saved Serial Numbers',
  props<{ serialNumbers: string[] }>(),
);

export const getCompanies = createAction(
  '[Search/API] Get Companies',
  props<{ articleNumber: string }>(),
);

export const setCompanies = createAction(
  '[Search/API] Set Companies',
  props<{
    articleNumber: string;
    companies: Company[] | null;
  }>(),
);

export const loadScannedEquipmentArticle = createAction(
  '[Search/API] Load Scanned Equipment Article',
  props<{ articleNumber: string; searchType: ModalKey }>(),
);

export const loadScannedEquipmentArticleSuccess = createAction(
  '[Search/API] Load Scanned Equipment Article Success',
  props<{
    articleNumber: string;
    results: ScannedItem[] | null;
  }>(),
);
