import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogData } from '@interfaces';
import { HotToastRef } from '@ngneat/hot-toast';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';
import { ErrorComponent } from '../error/error.component';

@Component({
  selector: 'frontend-workspace-error-toast',
  templateUrl: './error-toast.component.html',
  styleUrls: ['./error-toast.component.scss'],
  standalone: false,
})
export class ErrorToastComponent {
  constructor(
    @Optional()
    @Inject(HotToastRef)
    public toastRef: HotToastRef<{
      dialogTitle: string;
      toastMessage: string;
      error: HttpErrorResponse;
    }>,
    private readonly _dialog: MatDialog,
  ) {}

  openDialog(): void {
    const { error } = this.toastRef.data;
    const nativeError = error?.error?.error;
    const errorMessages: string[] = [];
    if (nativeError?.correlationId) {
      errorMessages.push(`Correlation id: ${nativeError.correlationId}`);
      errorMessages.push(`Error code: ${nativeError.errorCode}`);
      errorMessages.push(`Touchpoint Error: ${nativeError.touchpointError}`);
    }
    this.toastRef.close();

    const errorData: ErrorDialogData = {
      title: this.toastRef.data.dialogTitle,
      description: errorMessages.length === 0 ? error.message : '',
      errors: errorMessages,
    };

    this._dialog.open(CustomModalComponent, {
      data: {
        component: ErrorComponent,
        nestedData: errorData,
        title: errorData.title,
      },
      width: '50%',
      height: 'auto',
    });
  }
}
