import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as SearchActions from '@core-app/state/search/search.actions';
import { SearchState } from '@core-app/state/search/search.reducer';
import {
  searchLoading,
  selectSavedScannedEquipmentArticles,
  selectScannedEquipmentArticleSearchResult,
} from '@core-app/state/search/search.selectors';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

@Component({
  selector: 'fip-equipment-search-modal',
  templateUrl: './equipment-search.modal.html',
  styleUrls: ['./equipment-search.modal.scss'],
  standalone: false,
})
export class EquipmentSearchModalComponent implements OnInit, OnDestroy {
  readonly modalKey: ModalKey = 'part-search_equipment-search';
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store<SearchState>);
  private readonly _router = inject(Router);

  savedSerialNumbers$ = this._store.select(selectSavedScannedEquipmentArticles);
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchFormGroup = new FormGroup({
    partNumber: new FormControl('', [Validators.required]),
  });
  isSearchTriggered = false;

  ngOnInit() {
    // Disable form group while loading...
    this.searchLoading$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((loading) => {
        if (loading) {
          this.searchFormGroup.disable();
        } else {
          this.searchFormGroup.enable();
        }
      });
  }

  submit(serialNumber?: string) {
    if (serialNumber) {
      this.searchFormGroup.get('partNumber')?.setValue(serialNumber);
    }

    const param = serialNumber ?? this.searchFormGroup.value.partNumber;
    if (!param || !this.searchFormGroup.valid) {
      return;
    }

    const articleNumber = this.searchFormGroup.value.partNumber;

    if (articleNumber) {
      this.isSearchTriggered = true;

      // Listen to search result from ngrx store
      this._store
        .select(selectScannedEquipmentArticleSearchResult(articleNumber))
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          tap((data) => {
            if (!data) {
              if (articleNumber) {
                this._store.dispatch(
                  SearchActions.loadScannedEquipmentArticle({
                    articleNumber,
                    searchType: this.modalKey,
                  }),
                );
              }
            }
          }),
          tap((data) => {
            if (data) {
              if (articleNumber) {
                this._store.dispatch(
                  SearchActions.reorderSearchResults({
                    searchType: 'scannedEquipmentArticleSearchResults',
                    payload: {
                      articleNumber,
                    },
                  }),
                );
              }
            }
          }),
        )
        .subscribe((data) => {
          const { partNumber } = this.searchFormGroup.value;

          if (data?.length) {
            this._router.navigate([`/production/scanned-items/`], {
              queryParams: {
                partNumber,
              },
            });
          }
        });
    }
  }

  ngOnDestroy() {
    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );
  }
}
