import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';

@Component({
  selector: 'fip-documents-modal',
  templateUrl: './documents.modal.html',
  styleUrls: ['./documents.modal.scss'],
  standalone: false,
})
export class DocumentsModalComponent {
  readonly modalKey: ModalKey = 'documents';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      files: {
        url: string;
        fileName: string;
      }[];
    },
  ) {}
}
