import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { ComponentDataState } from '@enums';
import {
  findChildFromNestedObject,
  findChildrenFromNestedObject,
  removeDuplicatesFromNestedObject,
} from '@helpers';
import { BomNode } from '@interfaces';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';
import { selectBatchLoading } from '../state/batch/batch.selectors';

@Component({
  selector: 'fip-batch-bom',
  templateUrl: './batch-bom.component.html',
  styleUrls: ['./batch-bom.component.scss'],
  standalone: false,
})
export class BatchBomComponent implements OnInit, OnChanges {
  @Input({ required: true }) bom: BomNode;

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _route = inject(ActivatedRoute);
  private readonly _store = inject(Store);

  bomLoading$ = this._store.select(selectBatchLoading());

  batchesControl = new FormControl(['']);
  showBomControl = new FormControl(true);
  filteredOptions: Observable<string[]>;
  selectedBatchIds: BomNode['batchId'][] = [];
  selectedArticleId: BomNode['articleId'];
  dataState: ComponentDataState = ComponentDataState.Loading;
  ComponentDataState = ComponentDataState;

  // Tree attributes
  nestedDataSource = new MatTreeNestedDataSource<BomNode>();
  nestedTreeControl = new NestedTreeControl<BomNode>((node) => node.children);

  @Output() selectArticle = new EventEmitter<{
    articleId: string;
    batchIds: string;
  }>();

  @Output() selectBatch = new EventEmitter<string[]>();

  ngOnInit() {
    this.showBomControl.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((showBom) => !showBom),
      )
      .subscribe(() => {
        this.nestedTreeControl.collapseAll();
      });
  }

  findParentNode(nodeToFind: BomNode, nodes: BomNode[]): BomNode | null {
    for (const node of nodes) {
      if (node.children && node.children.length > 0) {
        if (node.children.includes(nodeToFind)) {
          return node;
        } else {
          const parent = this.findParentNode(nodeToFind, node.children);
          if (parent) {
            return parent;
          }
        }
      }
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    let currentBatchIds: BomNode['batchId'][] = [];

    if (changes['bom']?.currentValue?.children) {
      this.nestedDataSource.data = [
        removeDuplicatesFromNestedObject(this.bom, 'articleId'),
      ];

      this._selectCurrentArticleIdFromBomTree();

      const articleId = this._route.snapshot.queryParams['articleId'];

      const nodes = findChildrenFromNestedObject(
        this.bom,
        'articleId',
        articleId,
      );

      if (nodes?.length) {
        currentBatchIds = nodes?.map((node) => node.batchId);
      }
    }

    if (changes['bom']?.currentValue) {
      const batchIds = currentBatchIds.length
        ? currentBatchIds
        : [changes['bom'].currentValue.batchId];

      this.selectedBatchIds = batchIds;

      this.batchesControl.setValue(batchIds);

      this.dataState = ComponentDataState.HasData;
    } else {
      this.dataState = ComponentDataState.NoData;
    }
  }

  hasNestedChild(_: number, node: BomNode) {
    return node?.children?.length > 0;
  }

  private _selectCurrentArticleIdFromBomTree() {
    const articleId = this._route.snapshot.queryParams['articleId'];
    const nodeToExpand = findChildFromNestedObject(
      this.nestedDataSource.data[0],
      'articleId',
      articleId,
    );

    this.selectedArticleId = articleId;
    if (nodeToExpand) {
      // Find and expand the parent nodes first
      let parentNode = this.findParentNode(
        nodeToExpand,
        this.nestedDataSource.data,
      );
      while (parentNode) {
        // Expand the parent node
        this.nestedTreeControl.expand(parentNode);
        parentNode = this.findParentNode(
          parentNode,
          this.nestedDataSource.data,
        );
      }

      // Then, expand the node and its descendants
      this.nestedTreeControl.expand(nodeToExpand);
    }
  }

  /** Get all batch IDs from a BOM object, no matter how nested the object is */
  private _getBatchIdsByArticleId(
    bom: BomNode,
    articleId: BomNode['articleId'],
  ) {
    const batchIds: BomNode['batchId'][] = [];

    function getBatchId(obj: BomNode) {
      if (obj.batchId && obj.articleId === articleId) {
        batchIds.push(obj.batchId);
      }

      if (obj.children?.length > 0) {
        obj.children.forEach(getBatchId);
      }
    }

    getBatchId(bom);
    return batchIds;
  }

  selectArticleId(articleId: BomNode['articleId']) {
    const batchIds = this._getBatchIdsByArticleId(this.bom, articleId);
    this.selectedBatchIds = batchIds;
    this.batchesControl.setValue(batchIds);
    this.selectedArticleId = articleId;

    this.selectArticle.emit({ articleId, batchIds: batchIds?.join(',') });
  }
}
