import { Component, inject } from '@angular/core';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { MonitorType } from '@enums';
import { createNewFailureDuringIncomingInspectionFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { Monitor } from '@interfaces';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fip-failure-during-incoming-inspection-modal',
  templateUrl: './failure-during-incoming-inspection-modal.component.html',
  styleUrl: './failure-during-incoming-inspection-modal.component.scss',
  standalone: false,
})
export class FailureDuringIncomingInspectionModalComponent {
  private readonly _store = inject(Store);

  formGroup = createNewFailureDuringIncomingInspectionFormGroup();

  submit() {
    const {
      name,
      recipientGroupId,
      approversGroupId,
      initialQueryDate,
      companyId,
    } = this.formGroup.value;

    const monitor: Monitor = {
      partitionKey: 'v1',
      monitorType: MonitorType.FailureDuringIncomingInspection,
      name,
      recipientGroupId,
      approversGroupId,
      initialQueryDate,
      companyId,
      priorityLevel: 'P2',
    };

    this._store.dispatch(AlertingActions.upsertMonitor({ monitor }));
  }
}
