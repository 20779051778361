import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { ChartShape, LineChartDataPoints } from '@interfaces';
import { PlotlyService } from 'angular-plotly.js';

export interface ChartTrace {
  [key: string]: unknown;
}

const EXTRA_VALUE_PADDING_PERCENTAGE = 0.1;

@Component({
  selector: 'fip-generic-chart',
  templateUrl: './generic-chart.component.html',
  styleUrls: ['./generic-chart.component.scss'],
  standalone: false,
})
export class GenericChartComponent implements OnInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() curves: any[] = [];
  @Input() title = '';
  @Input() mode = 'lines+markers';
  @Input() xTitle = '';
  @Input() yTitle = '';
  @Input() width = 640;
  @Input() height = 400;
  @Input() xTicksVals?: number[];
  @Input() xTicksLabels?: string[];
  @Input() yTicksVals?: number[];
  @Input() yTicksLabels?: string[];
  @Input() shapes: ChartShape[] = [];
  @Input() defaultMinYValue: number | undefined;
  @Input() defaultMinXValue: number | undefined;
  @Input() defaultMaxYValue: number | undefined;
  @Input() setAutoMinYValue = false;
  @Input() setAutoMinXValue = false;
  @Input() exportable = true;
  @Input() showFirstMarker = true;
  @Input() showLastMarker = true;
  @Input() markerSize = 3;
  @Input() tickAngle = 0;
  @Input() showLegend = false;
  @Input() legendConfig = {};
  // A bit hacky, but sometimes plotly would draw a rectangle when shapes is an empty array
  @Input() forceHideShapes = false;

  protected readonly _cdr = inject(ChangeDetectorRef);
  private readonly plotlyService = inject(PlotlyService);

  divId = 'plotly-data-graph';

  data: any[] = [];

  mainLineConfig = {
    type: 'scatter',
    mode: 'lines+markers',
    marker: { color: '#C0C0C0', size: 3 },
  };

  firstMarkerConfig = {
    mode: 'markers',
    name: 'Scatter',
    marker: { color: 'rgb(255, 200, 0)', size: 10 },
  };

  lastMarkerConfig = {
    mode: 'markers',
    name: 'Scatter',
    marker: { color: 'rgb(0, 0, 0)', size: 10 },
  };

  config = {
    scrollZoom: false,
    editable: false,
    staticPlot: false,
    responsive: true,
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'autoScale2d',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
      'pan2d',
      'resetScale2d',
      'zoomOut2d',
      'zoomIn2d',
      'zoom',
      'toImage',
    ],
    displaylogo: false,
  };

  layout = {
    width: this.width,
    height: this.height,
    autosize: false,
    showlegend: false,
    margin: {
      t: 20,
    },
    legend: {
      y: 0.5,
      font: { size: 16 },
      yref: 'paper',
    },
    title: '&nbsp;',
    shapes: [{}],
    xaxis: {
      title: {
        text: '',
        standoff: 20,
      },
      showgrid: false,
      showline: true,
      zeroline: false,
      fixedrange: true,
      ticks: 'inside',
      range: [0, 1],
      ticktext: this.xTicksLabels,
      tickvals: this.xTicksVals,
      tickangle: 0,
      margin: {
        l: 25,
        r: 25,
        b: 50,
        t: 50,
        pad: 4,
      },
    },
    yaxis: {
      title: {
        text: '',
        standoff: 10,
      },
      showgrid: false,
      showline: true,
      zeroline: false,
      fixedrange: true,
      ticks: 'inside',
      range: [0, 1],
      ticktext: this.yTicksLabels,
      tickvals: this.yTicksVals,
      automargin: false,
      margin: {
        l: 25,
        r: 25,
        b: 50,
        t: 50,
        pad: 4,
      },
    },
  };

  ngOnInit(): void {
    this.layout.title = this.title;
    this.layout.xaxis.title.text = this.xTitle || '';
    this.layout.yaxis.title.text = this.yTitle || '';
    this.refreshGraph();
  }

  refreshGraph() {
    this.data = [];
    let xMaxValue = 0;
    let minXValue = Number.MAX_SAFE_INTEGER;
    let yMaxValue = 0;
    let yMinValue = Number.MAX_SAFE_INTEGER;
    const data = [...(this.shapes as Plotly.Data[])];

    for (let i = 0; i < this.curves.length; i++) {
      const curve = this.curves[i];

      if (this.getMaxXaxisValue(curve) > xMaxValue) {
        xMaxValue = this.getMaxXaxisValue(curve);
      }
      if (this.getMaxYaxisValue(curve) > yMaxValue) {
        yMaxValue = this.getMaxYaxisValue(curve);
      }

      if (this.setAutoMinYValue) {
        if (this.getMinYaxisValue(curve) < yMinValue) {
          yMinValue = this.getMinYaxisValue(curve);
        }
      }
      if (this.setAutoMinXValue) {
        if (Math.min(...curve.x) < minXValue) {
          minXValue = Math.min(...curve.x);
        }
      }

      data.push(this.createMainLine(curve));

      if (this.showFirstMarker) {
        data.push(this.createFirstMarker(curve));
      }

      if (this.showLastMarker) {
        data.push(this.createLastMarker(curve));
      }

      if (this.tickAngle) {
        this.layout.xaxis.tickangle = this.tickAngle;
      }
    }
    this.data = data;

    this.layout.yaxis.range = [
      // if defaultMinYValue is undefined, then yMinValue or 0 will be used
      // if defaultMinYValue is defined, then Math.min(this.defaultMinYValue, yMinValue) will be used
      // if yMinValue is undefined, then 0 will be used
      Math.min(this.defaultMinYValue ?? yMinValue, yMinValue ?? 0),
      Math.max(yMaxValue, this.defaultMaxYValue ?? 0),
    ];

    this.layout.xaxis.range = [this.defaultMinXValue ?? minXValue, xMaxValue];

    this.layout.xaxis.ticktext = this.xTicksLabels;
    this.layout.xaxis.tickvals = this.xTicksVals;
    this.layout.yaxis.tickvals = this.yTicksVals;
    this.layout.yaxis.ticktext = this.yTicksLabels;

    if (this.forceHideShapes) {
      this.layout.shapes = [];
    } else {
      this.layout.shapes = this.shapes;
    }
    this.configLegend();
  }

  private createMainLine(trace: any) {
    return <Plotly.Data>{
      x: trace.x,
      y: trace.y,
      type: trace.type || this.mainLineConfig.type,
      mode: trace.mode || this.mode || this.mainLineConfig.mode,
      hoverinfo: 'x+y',
      name: trace.name || '',
      marker: {
        color: trace.marker?.color || this.mainLineConfig.marker.color,
        size:
          trace.marker?.size ||
          this.markerSize ||
          this.mainLineConfig.marker.size,
      },
    };
  }

  createFirstMarker(dataPoint: LineChartDataPoints) {
    return <Plotly.Data>{
      x: [dataPoint.x[0]],
      y: [dataPoint.y[0]],
      mode: this.firstMarkerConfig.mode,
      hovermode: false,
      hoverinfo: 'none',
      marker: {
        color: this.firstMarkerConfig.marker.color,
        size: this.lastMarkerConfig.marker.size,
      },
    };
  }

  createLastMarker(dataPoint: LineChartDataPoints) {
    return <Plotly.Data>{
      x: [dataPoint.x[dataPoint.x.length - 1]],
      y: [dataPoint.y[dataPoint.y.length - 1]],
      mode: this.lastMarkerConfig.mode,
      hovermode: false,
      hoverinfo: 'none',
      marker: {
        color: this.lastMarkerConfig.marker.color,
        size: this.lastMarkerConfig.marker.size,
      },
    };
  }

  getMaxXaxisValue(dataPoint: LineChartDataPoints) {
    let maxValue = Number.MIN_SAFE_INTEGER;

    for (let i = 0; i < dataPoint.x.length; i++) {
      if (Number.isFinite(dataPoint.x[i]) && dataPoint.x[i] > maxValue) {
        maxValue = dataPoint.x[i];
      }
    }

    return Math.ceil(maxValue * (1 + EXTRA_VALUE_PADDING_PERCENTAGE / 100));
  }

  getMaxYaxisValue(dataPoint: LineChartDataPoints) {
    let maxValue = Number.MIN_SAFE_INTEGER;

    for (let i = 0; i < dataPoint.y.length; i++) {
      if (Number.isFinite(dataPoint.y[i]) && dataPoint.y[i] > maxValue) {
        maxValue = dataPoint.y[i];
      }
    }

    return Math.ceil(maxValue * (1 + EXTRA_VALUE_PADDING_PERCENTAGE / 100));
  }

  getMinYaxisValue(dataPoint: LineChartDataPoints) {
    let minValue = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < dataPoint.y.length; i++) {
      if (Number.isFinite(dataPoint.y[i]) && dataPoint.y[i] < minValue) {
        minValue = dataPoint.y[i];
      }
    }

    return Math.floor(minValue * (1 - EXTRA_VALUE_PADDING_PERCENTAGE / 100));
  }

  async handleExportClick() {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.divId);
    (await this.plotlyService.getPlotly()).downloadImage(graphDiv, {
      format: 'svg',
      filename: `export-${new Date().getTime()}`,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataPoints']) {
      this.refreshGraph();
      this._cdr.detectChanges();
    }

    if (changes['width']?.currentValue) {
      this.layout.width = changes['width'].currentValue;
    }
    if (changes['height']?.currentValue) {
      this.layout.height = changes['height'].currentValue;
    }
  }

  configLegend() {
    this.layout.showlegend = this.showLegend;
    this.layout.legend = Object.assign(this.layout.legend, this.legendConfig);
  }
}
