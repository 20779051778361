import { Component, inject } from '@angular/core';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { MonitorType } from '@enums';
import { createNewReductionInShipmentSizeFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { Monitor } from '@interfaces';
import { Store } from '@ngrx/store';

@Component({
  selector: 'frontend-workspace-reduction-shipment-size-modal',
  templateUrl: './reduction-shipment-size-modal.component.html',
  styleUrl: './reduction-shipment-size-modal.component.css',
  standalone: false,
})
export class ReductionShipmentSizeModalComponent {
  private readonly _store = inject(Store);

  formGroup = createNewReductionInShipmentSizeFormGroup();

  submit() {
    const { name, recipientGroupId, edi, shipmentId } = this.formGroup.value;

    const monitor: Monitor = {
      partitionKey: 'v1',
      monitorType: MonitorType.ReductionShipmentSize,
      name,
      recipientGroupId,
      edi,
      shipmentId,
      priorityLevel: 'P1',
    };

    this._store.dispatch(AlertingActions.upsertMonitor({ monitor }));
  }
}
