import { Component, DestroyRef, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as SearchActions from '@core-app/state/search/search.actions';
import {
  searchLoading,
  selectSPCArticleSearchResultsByArticlePayload,
  selectSavedSPCArticles,
} from '@core-app/state/search/search.selectors';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { DATE_FORMAT, compareDates, formatToDate } from '@helpers';
import { ArticlePayload, ArticleSearchResult } from '@interfaces';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'fip-spc-article-search-modal',
  templateUrl: './spc-article-search.modal.html',
  styleUrls: ['./spc-article-search.modal.scss'],
  standalone: false,
})
export class SPCArticleSearchModalComponent implements OnDestroy {
  readonly modalKey: ModalKey = 'spc_article-search';
  readonly displayedColumns = ['batch'];
  readonly columns = [
    {
      index: 'batch',
      label: 'Batch',
      key: 'batchId' as keyof ArticleSearchResult,
    },
  ];
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store);
  private readonly _router = inject(Router);

  searchFormGroup = new FormGroup({
    articleNumber: new FormControl('', [Validators.required]),
    productionStart: new FormControl(dayjs(), [Validators.required]),
    productionEnd: new FormControl(dayjs(), [Validators.required]),
  });
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: { articleNumber: string; results: ArticleSearchResult[] };
  savedArticles$ = this._store.select(selectSavedSPCArticles);

  constructor() {
    // Disable form group while loading...
    this.searchLoading$.pipe(takeUntilDestroyed()).subscribe((loading) => {
      if (loading) {
        this.searchFormGroup.disable();
      } else {
        this.searchFormGroup.enable();
      }
    });
  }

  rowClicked(row: ArticleSearchResult) {
    this._router.navigate(['spc/workbench'], {
      queryParams: {
        rootArticleId: row.articleId,
        articleId: row.articleId,
        batchId: row.batchId,
        rootBatchId: row.batchId,
      },
    });
  }

  submit(
    articlePayload?:
      | ArticlePayload
      | {
          articleNumber: string;
          productionFrom: string;
          productionUntil: string;
        },
  ) {
    // check if articlePayload is coming from localstorage
    // in this case, the dates are strings and need to be converted to dayjs object
    if (articlePayload) {
      articlePayload = {
        ...articlePayload,
        productionFrom:
          typeof articlePayload?.productionFrom === 'string'
            ? dayjs(articlePayload.productionFrom, DATE_FORMAT)
            : articlePayload.productionFrom,
        productionUntil:
          typeof articlePayload?.productionUntil === 'string'
            ? dayjs(articlePayload.productionUntil, DATE_FORMAT)
            : articlePayload.productionUntil,
      };

      const { articleNumber, productionFrom, productionUntil } = articlePayload;

      this.searchFormGroup.setValue({
        articleNumber,
        productionStart: dayjs(productionFrom),
        productionEnd: dayjs(productionUntil),
      });
    }

    const { articleNumber, productionStart, productionEnd } =
      this.searchFormGroup.value;

    const param: ArticlePayload =
      articlePayload ??
      ({
        articleNumber,
        productionFrom: dayjs(productionStart),
        productionUntil: dayjs(productionEnd),
      } as ArticlePayload);

    if (!param || !this.searchFormGroup.valid) {
      return;
    }

    // Listen to search result from ngrx store
    this._store
      .select(selectSPCArticleSearchResultsByArticlePayload(param))
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap((data) => {
          if (
            data?.articleNumber !== param.articleNumber ||
            !compareDates(data.productionFrom, param.productionFrom) ||
            !compareDates(data.productionUntil, param.productionUntil)
          ) {
            this._store.dispatch(
              SearchActions.loadSpcArticle({
                articleNumber: param.articleNumber,
                productionFrom: dayjs(param.productionFrom),
                productionUntil: dayjs(param.productionUntil),
                searchType: this.modalKey,
              }),
            );
          }
        }),
        filter((data) => !!data),
        tap((data) => {
          if (data) {
            this._store.dispatch(
              SearchActions.reorderSearchResults({
                searchType: 'spcArticleSearchResults',
                payload: {
                  articleNumber: data.articleNumber,
                  productionFrom: formatToDate(data.productionFrom) || '',
                  productionUntil: formatToDate(data.productionUntil) || '',
                },
              }),
            );
          }
        }),
      )
      .subscribe((data) => (this.searchResult = data));
  }

  ngOnDestroy() {
    // Clear search result before closing modal
    this.searchResult = undefined;

    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );
  }
}
