import {
  Article,
  BatchHeader,
  Company,
  DeliveryCore,
  DeliveryPosition,
  EightDReport,
  Order,
  ProductionNumber,
  ScannedItem,
  SingleItem,
} from '@interfaces';
import { createActionGroup, props } from '@ngrx/store';
import * as dayjs from 'dayjs';

export const productionActions = createActionGroup({
  source: 'Production API',

  events: {
    'Get Production Stocks': props<{
      articleId: Article['articleId'];
      companyCode: string;
    }>(),
    'Set Production Stocks': props<{
      articleId: Article['articleId'];
      data: ProductionNumber[] | null;
    }>(),
    'Toggle Production Stocks Loading': props<{
      loading: boolean;
    }>(),

    'Get EightDReports': props<{
      articleNumber: Article['articleId'];
      batchId?: BatchHeader['batchId'];
      fromTime: string;
      to: string;
      stationNum?: string;
      downtimeCategory?: string;
    }>(),
    'Set EightDReports': props<{
      data: EightDReport[] | null;
    }>(),
    'Toggle EightDReports Loading': props<{
      loading: boolean;
    }>(),

    'Get OEE Metrics': props<{
      stationNum: string;
      companyCode: Company['code'];
      from: dayjs.Dayjs | null | undefined;
      to: dayjs.Dayjs | null | undefined;
    }>(),
    'Toggle OEE Metrics Loading': props<{
      loading: boolean;
    }>(),
    'Set OEE Metrics': props<{
      data: {
        target: number;
        availability: number;
        performance: number;
        quality: number;
      } | null;
    }>(),

    'Get Availability Data': props<{
      stationNum: string;
      companyCode: Company['code'];
      from: dayjs.Dayjs | null | undefined;
      to: dayjs.Dayjs | null | undefined;
    }>(),

    'Set Availability Data': props<{
      data: any;
    }>(),

    'Toggle Availability Data Loading': props<{
      loading: boolean;
    }>(),

    'Get Performance Data': props<{
      stationNum: string;
      companyCode: Company['code'];
      from: dayjs.Dayjs | null | undefined;
      to: dayjs.Dayjs | null | undefined;
    }>(),

    'Set Performance Data': props<{
      data: any;
    }>(),

    'Toggle Performance Data Loading': props<{
      loading: boolean;
    }>(),

    'Get Quality Data': props<{
      stationNum: string;
      companyCode: Company['code'];
      from: dayjs.Dayjs | null | undefined;
      to: dayjs.Dayjs | null | undefined;
    }>(),

    'Set Quality Data': props<{
      data: any;
    }>(),

    'Toggle Quality Data Loading': props<{
      loading: boolean;
    }>(),

    'Get Orders': props<{
      articleId: string | null | undefined;
      companyCode: string | null | undefined;
      fromDate: dayjs.Dayjs | null | undefined;
      toDate: dayjs.Dayjs | null | undefined;
    }>(),

    'Set Orders': props<{
      orders: Order[];
    }>(),

    'Toggle Orders Loading': props<{
      loading: boolean;
    }>(),

    'Select Order': props<{
      order: Order;
    }>(),

    'Get Deliveries Core': props<{
      deliveryNoteId: string;
      companyCode: string;
    }>(),

    'Get Deliveries Positions': props<{
      deliveryNoteId: string;
      companyCode: string;
    }>(),

    'Set Deliveries Core': props<{
      deliveries: DeliveryCore[];
    }>(),

    'Set Deliveries Positions': props<{
      positions: DeliveryPosition[];
    }>(),

    'Toggle Deliveries Loading': props<{
      loading: boolean;
    }>(),

    'Get Companies': props<{
      articleId?: string;
    }>(),

    'Set Companies': props<{
      companies: Company[];
    }>(),

    'Get SingleItems': props<{
      articleId: string;
      batchId: string;
    }>(),

    'Set Single Items': props<{
      singleItems: SingleItem[];
    }>(),

    'Toggle Single Items Loading': props<{
      loading: boolean;
    }>(),

    'Get Scanned Items': props<{
      partNumber: string;
    }>(),

    'Set Scanned Items': props<{
      scannedItems: ScannedItem[];
    }>(),

    'Toggle Scanned Items Loading': props<{
      loading: boolean;
    }>(),
  },
});
