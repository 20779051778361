import { Component, Inject, inject } from '@angular/core';
import { AuthService } from '@core-app/services/auth.service';
import { FeatureFlagsService } from '@core-app/services/feature-flags.service';
import { APP_CONFIG } from '@frontend-workspace/app-config';

@Component({
  selector: 'frontend-workspace-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  standalone: false,
})
export class AuthComponent {
  readonly featureFlags = inject(FeatureFlagsService);

  loginDisplay = false;
  appVersion: string = this._appConfig.displayVersionNumber;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(APP_CONFIG) private readonly _appConfig: any,
    private readonly _authService: AuthService,
  ) {
    this._authService.saveAuthenticatedUser();
  }

  login() {
    this._authService.login();
  }
}
