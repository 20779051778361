import { Component, inject } from '@angular/core';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { MonitorType } from '@enums';
import { createNewDailyProductionTargetMissedFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { Monitor } from '@interfaces';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fip-daily-production-target-missed-modal',
  templateUrl: './daily-production-target-missed-modal.component.html',
  styleUrl: './daily-production-target-missed-modal.component.scss',
  standalone: false,
})
export class DailyProductionTargetMissedModalComponent {
  private readonly _store = inject(Store);

  formGroup = createNewDailyProductionTargetMissedFormGroup();

  submit() {
    const { name, recipientGroupId, productionId, productionTarget } =
      this.formGroup.value;

    const monitor: Monitor = {
      partitionKey: 'v1',
      monitorType: MonitorType.DailyProductionTargetMissedByXPercent,
      name,
      recipientGroupId,
      productionId,
      productionTarget,
      priorityLevel: 'P2',
    };

    this._store.dispatch(AlertingActions.upsertMonitor({ monitor }));
  }
}
