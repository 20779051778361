<div class="part-search-modal">
  <div class="flex">
    <div class="flex-1 p-4">
      <form [formGroup]="searchFormGroup" (submit)="submit()">
        <div class="flex gap-2">
          <fip-form>
            <mat-form-field>
              <mat-label>Article Number</mat-label>
              <input
                matInput
                placeholder="e.g. P1234"
                formControlName="articleNumber"
              />
            </mat-form-field>
          </fip-form>
          <fip-form formType="daterange-picker" class="flex-1">
            <mat-form-field class="w-full">
              <mat-label>Production Date</mat-label>
              <mat-date-range-input
                [rangePicker]="production"
                [comparisonStart]="searchFormGroup.value.productionStart"
                [comparisonEnd]="searchFormGroup.value.productionEnd"
              >
                <input
                  matStartDate
                  placeholder="Start date"
                  formControlName="productionStart"
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="productionEnd"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="production"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #production></mat-date-range-picker>
            </mat-form-field>
          </fip-form>
          <fip-form formType="submit">
            <fip-button
              [loading]="(searchLoading$ | async)!"
              (clickEvent)="submit()"
              label="Search"
              backgroundColor="black"
              textColor="white"
              classes="mt-3 px-3 py-2 sm:ml-3 sm:mt-0 sm:w-auto shadow-sm text-sm"
            ></fip-button>
          </fip-form>
        </div>
        <!-- Commented until endpoint includes fields below -->
        <!-- <div class="flex gap-2">
          <fip-form>
            <mat-form-field>
              <mat-label>Assembly lines</mat-label>
              <input
                type="text"
                placeholder="Choose a line"
                matInput
                formControlName="assemblyLines"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </fip-form>
          <fip-form>
            <mat-form-field>
              <mat-label>Stations</mat-label>
              <input
                type="text"
                placeholder="Select a station"
                matInput
                formControlName="stations"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </fip-form>
          <fip-form>
            <mat-form-field>
              <mat-label>Inspections</mat-label>
              <input
                type="text"
                placeholder="Select inspections"
                matInput
                formControlName="inspections"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </fip-form>
        </div> -->
      </form>

      <ng-container *ngIf="!(searchLoading$ | async)">
        <div
          data-cy="article-search-incoming-result"
          class="pt-2"
          *ngIf="searchResult; else noResult"
        >
          <fip-table
            [data]="searchResult.results"
            [displayedColumns]="displayedColumns"
            [columns]="columns"
            [paginated]="true"
            [contained]="true"
            (rowClicked)="rowClicked($event)"
          ></fip-table>
        </div>
        <ng-template #noResult>
          <div data-cy="article-search-incoming-no-result" class="pt-2">
            No results found.
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div
      data-cy="serial-numbers-list"
      class="flex-1 self-stretch bg-gray-100 px-4 pb-10 pt-4"
    >
      <h4 class="text-base font-semibold leading-6 text-gray-900">Recent</h4>
      <ng-container
        *ngIf="!(savedArticles$ | async)?.length; else savedArticleContainer"
      >
        <p class="text-sm text-gray-500">You have no recent search.</p>
      </ng-container>
      <ng-template #savedArticleContainer>
        <button
          class="group mt-2 flex text-sm"
          *ngFor="let article of savedArticles$ | async"
          (click)="submit(article)"
        >
          <p
            class="text-sm font-semibold leading-6 text-gray-900 group-hover:text-gray-500"
          >
            - {{ article.articleNumber }}
          </p>
          <p
            class="ml-1 mt-0.5 whitespace-nowrap bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
          >
            {{ article.productionFrom }} - {{ article.productionUntil }}
          </p>
        </button>
      </ng-template>
    </div>
  </div>
</div>
