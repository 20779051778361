import { Injectable, inject } from '@angular/core';
import {
  ApiResponse,
  PartsAssemblyDTO,
  PartsLeakCheckDTO,
  PartsPositionCheckImageDTO,
  PartsSummaryDTO,
  ScannedItem,
  SingleItem,
} from '@interfaces';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PartsService {
  private readonly _apiPath = 'single-items';
  private readonly _api = inject(ApiService);

  getPartById(id: string) {
    return this._api.get<PartsSummaryDTO>(`${this._apiPath}/${id}`);
  }

  getPartPositionCheckImages(id: string) {
    return this._api.get<PartsPositionCheckImageDTO[]>(
      `${this._apiPath}/${id}/position-check-images`,
    );
  }

  getPartLeakCheck(id: string) {
    return this._api.get<PartsLeakCheckDTO[]>(
      `${this._apiPath}/${id}/leak-check`,
    );
  }

  getAssembly(id: string) {
    return this._api.get<PartsAssemblyDTO[]>(`${this._apiPath}/${id}/assembly`);
  }

  getSingleItemsByProductionOrder(id: string) {
    return this._api.get<PartsSummaryDTO[]>(
      `production-orders/${id}/single-items`,
    );
  }

  getPartsByArticle(
    articleId?: string,
    batchId?: string,
  ): Observable<ApiResponse<SingleItem[]>> {
    return this._api.get<SingleItem[]>(
      `${this._apiPath}/article/${articleId}/batch/${batchId}`,
    );
  }

  getScannedItems(articleId?: string): Observable<ApiResponse<ScannedItem[]>> {
    return of({
      data: [
        {
          id: '7dc71ef5-bbe4-4077-844a-463595bb2b9b',
          name: '  Montagevorrichtung kpl. VFS-ASM-13',
          factoryId: '17',
          equipmentTypeId: 5,
          workplaceNr: '509',
          workplaceId: '17_509',
          opcUAUrl: null,
          enableIoTHub: null,
          externalEquipmentId: '6011',
          serialNumber: '550975',
          buildingTypeSubject: 'VFS-ASM',
          additionalProperties: [],
          relations: [],
          toolPositions: null,
          drawingNumber: 'TAB-0439-K-M-E-00',
          isRequiredForHandScanning: true,
        },

        {
          id: 'e7e77a06-2e9c-484b-8f3d-2545421b2623',
          name: ' Connectoraufnahme kpl. Connector nest cpl. f. 878264',
          factoryId: '17',
          equipmentTypeId: 18,
          workplaceNr: '509',
          workplaceId: '17_509',
          opcUAUrl: null,
          enableIoTHub: null,
          externalEquipmentId: '8283',
          serialNumber: '878212',
          buildingTypeSubject: null,
          additionalProperties: [],
          relations: [],
          toolPositions: null,
          drawingNumber: '00878212',
          isRequiredForHandScanning: true,
        },
      ],
    });

    // return this._api.get<ScannedItem[]>(
    //   `${this._apiPath}/article/${articleId}/scanned-items`,

    // );
  }
}
