import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectReceiversGroups } from '@core-app/state/alerting';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { createNewReductionInShipmentSizeFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { RecipientGroup } from '@interfaces';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fip-reduction-shipment-size-form',
  templateUrl: './reduction-shipment-size-form.component.html',
  styleUrl: './reduction-shipment-size-form.component.scss',
  standalone: false,
})
export class ReductionShipmentSizeFormComponent implements OnInit {
  @Input() submitButtonText = 'Save';
  @Input() formGroup = createNewReductionInShipmentSizeFormGroup();
  @Output() submitForm = new EventEmitter();

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store);

  recipientGroups: RecipientGroup[] = [];

  ngOnInit(): void {
    this._store.dispatch(AlertingActions.fetchReceiversGroup());
    this._store
      .select(selectReceiversGroups())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => {
        if (data) {
          this.recipientGroups = data;
        }
      });
  }

  triggerValidation() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
  }

  submit() {
    this.triggerValidation();

    if (this.formGroup.valid) {
      this.submitForm.emit();
    }
  }
}
