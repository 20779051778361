import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { color, cursor } from '../../types';
import { SvgName } from '../svg/svg.component';

// Comment for Tailwind Prune
// bg-accent-yellow bg-accent-orange bg-accent-yellow bg-white bg-accent-light-blue
// bg-accent-blue bg-accent-green bg-accent-orange bg-accent-red bg-black bg-black-80
// bg-black-50 bg-black-15 bg-very-dark-grey bg-intense-grey bg-dark-grey bg-grey-area
// bg-light-grey bg-tab-col bg-area-text

// text-accent-yellow text-accent-orange text-accent-yellow text-white text-accent-light-blue
// text-accent-blue text-accent-green text-accent-orange text-accent-red text-black text-black-80
// text-black-50 text-black-15 text-very-dark-grey text-intense-grey text-dark-grey text-grey-area
// text-light-grey text-tab-col text-area-text
@Component({
  selector: 'fip-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: false,
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() iconLeft?: SvgName | string;
  @Input() iconRight?: SvgName | string;
  @Input() iconType?: 'svg' | 'material' = 'svg';
  @Input() size? = '24';
  @Input() labelTextClass?: string = '';
  @Input() backgroundColor: color = 'transparent';
  @Input() iconFillColor?: color | string = 'white';
  @Input() textColor: color = 'white';
  @Input() disabled?: boolean | null;
  @Input() small = false;
  @Input() classes? = '';
  @Input() img?: string;
  @Input() displayLabel?: boolean = true;
  @Input() btnTitle? = '';
  @Input() cursor: cursor = 'cursor-pointer';
  @Input() whenInteract: 'lighten' | 'darken' | 'white' | null = null;
  @Input() loading?: boolean = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Input({ required: false }) dataCy!: string;

  @Output() clickEvent: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  ngOnInit(): void {
    if (this.small) {
      this.size = '14';
    }

    if (this.disabled) {
      this.cursor = 'cursor-not-allowed';
    }
  }

  onButtonClicked(event?: MouseEvent): void {
    this.clickEvent.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && changes.disabled.currentValue) {
      this.cursor = 'cursor-not-allowed';
    } else {
      this.cursor = 'cursor-pointer';
    }
  }
}
